// import Header  from './components/Header';
import { ChakraProvider } from '@chakra-ui/react';
import Menu from './components/Menu';

import CTA from './components/CTA';
import Sobre from './components/Sobre';
import { FloatingWhatsApp } from 'react-floating-whatsapp'

import Footer from './components/Footer';

import theme from './Theme';


// import procedimentoLash from './assets/procedimentoLash.jpg';
import claraImagem from './assets/clara.png';
  /*global dataLayer*/


function App() {

  const NAV_ITEMS: Array<> = [
    {
      label: 'Sobre',
      href: `#sobre`
    },
    {
      label: 'Fale Conosco',
      href: 'https://wa.me/5515997143002?text=Ol%C3%A1,%20desejo%20mais%20informa%C3%A7%C3%B5es%20sobre%20agendamento%20da%20Clinic%20Haven',
    }
  ];

  const openLead = () => {
    dataLayer.push({
      'event': 'openBox'
    });
  }

  const contactSubmit = () => {
    dataLayer.push({
      'event': 'clickContact'
    });
  }

  return (
    <ChakraProvider theme={theme}>

        <Menu procedimentos={NAV_ITEMS} />
        <CTA />
        <Sobre />
        {/* <Avaliacoes /> */}
        <FloatingWhatsApp 
          phoneNumber={'+5515997143002'} 
          accountName={'Clara Pelegrini'} 
          statusMessage={'on-line'} 
          chatMessage={'Olá, tudo bem? Está pronta para agendar seu momento de refúgio 🧘🏼‍♀️?'} 
          avatar={claraImagem} 
          placeholder={'Digite uma mensagem'}
          onClick={openLead}  
          onSubmit={contactSubmit}
        />
        <Footer />
    </ChakraProvider>
  );
}

export default App;
