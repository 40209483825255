import {
    Box,
    chakra,
    Container,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
  } from '@chakra-ui/react';
  import { FaInstagram, FaTiktok, FaWhatsapp, FaFacebook } from 'react-icons/fa';
  import { ReactNode } from 'react';
  import Logo from './Logo';
  
  const SocialButton = ({
    children,
    label,
    href,
  }: {
    children: ReactNode;
    label: string;
    href: string;
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };
  
  export default function SmallCentered() {
    return (
      <Box
        bg={useColorModeValue('#e8ddd4', '#a07b61')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          spacing={4}
          justify={'center'}
          align={'center'}>
          <Logo base="100px" md="150px" />
        </Container>
  
        <Box
          borderTopWidth={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('#a07b61', '#a07b61')}>
          <Container
            as={Stack}
            maxW={'6xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}>
            <Text>© 2024 Grupo Haven. Todos os direitos reservados</Text>
            <Stack direction={'row'} spacing={6}>
              <SocialButton label={'Facebook'} href={'https://www.facebook.com/profile.php?id=100093682265074'}>
                <FaFacebook />
              </SocialButton>
              <SocialButton label={'WhatsApp'} href={'https://wa.me/5515997143002?text=Ol%C3%A1,%20desejo%20mais%20informa%C3%A7%C3%B5es%20sobre%20agendamento%20da%20Clinic%20Haven'}>
                <FaWhatsapp />
              </SocialButton>
              <SocialButton label={'TikTok'} href={'https://www.tiktok.com/@clinicahaven'}>
                <FaTiktok />
              </SocialButton>
              <SocialButton label={'Instagram'} href={'https://www.instagram.com/clinicahaven/'}>
                <FaInstagram />
              </SocialButton>
            </Stack>
          </Container>
        </Box>
      </Box>
    );
  }