import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  Box
} from '@chakra-ui/react';
import { BiSpa, BiUserCircle } from 'react-icons/bi';
import { IoMdLeaf } from 'react-icons/io';
import { GiAchievement } from "react-icons/gi";



import { ReactElement } from 'react';

import browLamination from '../assets/brow.jpeg'

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function SplitWithImage() {
  return (
    <Container maxW={'5xl'} py={12} id='sobre'>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={'uppercase'}
            color={'#486a66'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('#eaf0ee', '#486a66')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}>
            Sobre nossa clínica
          </Text>
          <Heading>Uma experiência Haven</Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
          Na Haven Clinic, mergulhe em uma experiência única de estética facial que combina relaxamento e naturalidade. Nossa proposta principal é proporcionar um refúgio de cuidado pessoal, onde realçamos sua beleza autêntica e oferecemos momentos de tranquilidade durante todo o atendimento. 
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('#e8ddd4', 'gray.700')}
              />
            }>
            <Feature
              icon={
                <Icon as={BiSpa} color={'#486a66'} w={5} h={5} />
              }
              iconBg={useColorModeValue('#e8ddd4', '#e8ddd4')}
              text={'Tempo Relaxante'}
            />
            <Feature
              icon={<Icon as={IoMdLeaf} color={'#486a66'} w={5} h={5} />}
              iconBg={useColorModeValue('#e8ddd4', '#e8ddd4')}
              text={'Sobrancelhas com Toque de Naturalidade'}
            />
            <Feature
              icon={
                <Icon as={GiAchievement} color={'#486a66'} w={5} h={5} />
              }
              iconBg={useColorModeValue('#e8ddd4', '#e8ddd4')}
              text={'Profissionais Experientes'}
            />
            <Feature
              icon={
                <Icon as={BiUserCircle} color={'#486a66'} w={5} h={5} />
              }
              iconBg={useColorModeValue('#e8ddd4', '#e8ddd4')}
              text={'Protocolos Personalizados'}
            />
          </Stack>
        </Stack>
        <Flex>

        <Box
              position={'relative'}
              height={'500px'}
              rounded={'2xl'}
              boxShadow={'2xl'}
              width={'full'}
              overflow={'hidden'}>
              <Image
                alt={'Haven Clinic'}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={
                  browLamination
                }
              />
            </Box>

        </Flex>
      </SimpleGrid>
    </Container>
  );
}