import logo from '../assets/logoPrincipal.jpg';
import {
    useBreakpointValue,
} from '@chakra-ui/react';

function Logo(props){
    const logoSize = useBreakpointValue({ base: props.base, md: props.md });
    return (
        <img src={logo} alt="Haven" style={{ width: logoSize, height: logoSize }} />
    )
}

export default Logo;